/* eslint-disable consistent-return */
/* eslint-disable no-plusplus */
/* eslint-disable no-nested-ternary */
import { removeDuplicates } from "mixins/helpers";
import { isIOS } from "react-device-detect";

const initializeHLSPlayer = (videoId, options = {}) => {
  let controlsInitialized = false;

  const {
    sources,
    onReady,
    onError,
    setHasLoaded,
    setVideoErrors,
    questionNumber,
    t,
    player,
    initializeControls
  } = options;

  // Initialize state tracking
  let state = {
    manifestLoaded: false,
    segmentsLoaded: false,
    isInitialSegmentLoaded: false,
    segmentAttempts: 0,
    lastError: null,
    isDestroyed: false,
    lastBufferCheck: Date.now(),
    bufferCheckCount: 0,
    qualityLevels: [],
    currentQuality: -1
  };

  // Configure VHS options
  window.videojs.options.vhs = {
    overrideNative: true,
    cacheEncryptionKeys: true,
    bandwidth: 5000000,
    bufferSize: 30,
    debug: process.env.NODE_ENV !== "production",
    limitRenditionByPlayerDimensions: false,
    useDevicePixelRatio: true,
    allowSeeksWithinUnsafeLiveWindow: true,
    backBufferLength: 30,
    experimentalBufferBasedABR: true,
    enableLowInitialPlaylist: true,
    fastQualityChange: true,
    useBandwidthFromLocalStorage: true,
    withCredentials: false,
    segments: {
      forwardBuffer: 30,
      backBuffer: 30,
      bandwidth: 5000000
    },
    retryOnError: true,
    maxPlaylistRetries: 2,
    maxBufferSize: 60,
    maxBufferLength: 30
  };

  // Initialize HLS tech
  const hlsTech = player.tech({ IWillNotUseThisInPlugins: true }).vhs;

  const logHLSState = event => {
    if (process.env.REACT_APP_ENV !== "production") {
      const buffered = player.buffered();
      const hasBuffer = buffered.length > 0;

      console.log(`HLS Event [${event}]:`, {
        manifestLoaded: state.manifestLoaded,
        segmentsLoaded: state.segmentsLoaded,
        isInitialSegmentLoaded: state.isInitialSegmentLoaded,
        segmentAttempts: state.segmentAttempts,
        currentQuality: state.currentQuality,
        qualityLevels: state.qualityLevels,
        buffered: hasBuffer ? {
          start: buffered.start(0),
          end: buffered.end(0),
          length: buffered.length,
          range: buffered.end(0) - buffered.start(0)
        } : null,
        duration: player.duration(),
        currentTime: player.currentTime(),
        readyState: player.readyState(),
        networkState: player.networkState(),
        error: player.error(),
        hlsStats: hlsTech?.stats
      });
    }
  };

  const debugLog = context => {
    if (process.env.REACT_APP_ENV !== "production") {
      console.log(`HLS Debug [${context}]:`, {
        hasInitializeControls: typeof initializeControls === "function",
        controlsInitialized,
        readyState: player?.readyState()
      });
    }
  };

  // Create safe initialization function
  const initializeControlsSafely = () => {
    debugLog("initializeControlsSafely");

    if (!controlsInitialized &&
        typeof initializeControls === "function" &&
        player &&
        !isIOS) {
      debugLog("Initializing controls");
      try {
        initializeControls(player);
        controlsInitialized = true;
      } catch (error) {
        console.error("Failed to initialize controls:", error);
      }
    }
  };

  const checkReadiness = () => {
    if (state.isDestroyed) return;

    const buffered = player.buffered();
    const hasBuffer = buffered.length > 0 && buffered.end(0) > 0;
    const techReady = player.readyState() >= 3;

    logHLSState("checkReadiness");

    // More lenient readiness check
    const isReady = state.manifestLoaded && (
      state.segmentsLoaded ||
      state.isInitialSegmentLoaded ||
      (hasBuffer && techReady) ||
      // Fallback check for iOS
      (player.readyState() >= 3 && hasBuffer)
    );

    if (isReady) {
      setHasLoaded(true);
      initializeControlsSafely();
      if (onReady) onReady();
    }

    return isReady;
  };

  const checkBuffer = () => {
    if (state.isDestroyed) return;

    // Throttle buffer checks
    if (Date.now() - state.lastBufferCheck < 500) return;
    state.lastBufferCheck = Date.now();
    state.bufferCheckCount++;

    const buffered = player.buffered();
    if (buffered.length > 0) {
      const bufferedStart = buffered.start(0);
      const bufferedEnd = buffered.end(0);
      const duration = player.duration();
      const bufferedRange = bufferedEnd - bufferedStart;

      logHLSState("bufferCheck");

      // Mark segments as loaded if ANY of these conditions are met
      if (bufferedRange > 3 ||
          (duration && bufferedRange / duration > 0.05) ||
          buffered.length > 1 ||
          (bufferedRange > 0 && player.readyState() >= 3) ||
          // Fallback: If we've checked buffer many times and have some data
          (state.bufferCheckCount > 10 && bufferedRange > 1)) {
        state.segmentsLoaded = true;
        checkReadiness();
      }
    }
  };

  const handleError = (error, context = "") => {
    if (state.isDestroyed) return;

    console.error(`HLS Error [${context}]:`, error);
    state.lastError = {
      error,
      timestamp: Date.now()
    };

    if (onError) {
      onError(error);
    }

    // Only update errors if we don't already have one for this question
    setVideoErrors(prevErrors => {
      const hasExistingError = prevErrors?.some(e => e.questionId === questionNumber);
      if (!hasExistingError) {
        return removeDuplicates([
          ...(prevErrors ?? []),
          {
            questionId: questionNumber,
            error: error.message || `${t("errors.interruptedVideoUpload.0")} \n ${t("errors.interruptedVideoUpload.1")}`,
            isMediaUploadInterrupted: error.code === 4,
            contactSupport: error.code !== 4
          }
        ], "questionId");
      }
      return prevErrors;
    });
  };

  // Set up HLS specific event handlers
  if (hlsTech) {
    // Handle playlist loading
    hlsTech.on("loadedplaylist", () => {
      logHLSState("loadedplaylist");
      state.manifestLoaded = true;

      // Try to select an appropriate quality level
      if (hlsTech.representations && hlsTech.representations()) {
        const representations = hlsTech.representations();
        state.qualityLevels = representations.map(rep => ({
          width: rep.width,
          height: rep.height,
          bandwidth: rep.bandwidth
        }));

        // Start with a middle quality for faster initial load
        const midQuality = Math.floor(representations.length / 2);
        if (representations[midQuality]) {
          state.currentQuality = midQuality;
          representations[midQuality].enabled = true;
        }
      }

      checkReadiness();
    });

    // Handle segment loading
    hlsTech.on("appendbufferend", () => {
      logHLSState("appendbufferend");
      if (!state.isInitialSegmentLoaded) {
        state.isInitialSegmentLoaded = true;
        state.segmentsLoaded = true;
        checkReadiness();
      }
    });

    // Handle quality changes
    hlsTech.on("mediachange", () => {
      logHLSState("mediachange");
      if (hlsTech.stats?.buffered?.length > 0) {
        state.segmentsLoaded = true;
        checkReadiness();
      }
    });

    // Handle segment failures
    hlsTech.on("segmentdrop", () => {
      state.segmentAttempts++;
      logHLSState("segmentdrop");

      if (state.segmentAttempts > 3) {
        handleError(new Error("Multiple segment loading failures"), "segmentdrop");
      }
    });
  }

  // Set up general player event handlers
  const eventHandlers = {
    loadedmetadata: () => {
      logHLSState("loadedmetadata");

      state.manifestLoaded = true;
      checkReadiness();

      initializeControlsSafely();
    },

    loadeddata: () => {
      logHLSState("loadeddata");
      initializeControlsSafely();
    },

    canplay: () => {
      logHLSState("canplay");
      checkBuffer();
    },

    canplaythrough: () => {
      logHLSState("canplaythrough");
      state.segmentsLoaded = true;
      checkReadiness();
    },

    playing: () => {
      logHLSState("playing");
      if (player.currentTime() > 0 && player.buffered().length > 0) {
        state.segmentsLoaded = true;
        checkReadiness();
      }
    },

    error: () => {
      const error = player.error();

      logHLSState("error");

      if (error.code === 4) { // MEDIA_ERR_SRC_NOT_SUPPORTED
        setHasLoaded(false);

        // Attempt recovery if we haven't recently had an error
        if (!state.lastError || Date.now() - state.lastError.timestamp > 5000) {
          player.src(sources[0]);
        }
      }
      handleError(error, "player");
    },

    seeking: () => {
      // Reset segment loaded state on seek to ensure proper buffer check
      state.segmentsLoaded = false;
      checkBuffer();
    },

    progress: checkBuffer,
    timeupdate: checkBuffer,

    sourceset: () => {
      logHLSState("sourceset");
      // Reset state
      state = {
        ...state,
        manifestLoaded: false,
        segmentsLoaded: false,
        isInitialSegmentLoaded: false,
        segmentAttempts: 0,
        bufferCheckCount: 0
      };
      setHasLoaded(false);
    }
  };

  // Attach all event handlers
  Object.entries(eventHandlers).forEach(([event, handler]) => {
    player.on(event, handler);
  });

  // Set up periodic buffer checking
  const bufferCheckInterval = setInterval(() => {
    if (!state.segmentsLoaded) {
      checkBuffer();
    }
  }, 1000);

  // Add backup timeout
  const initTimeout = setTimeout(() => {
    if (!controlsInitialized && player.readyState() >= 2) {
      if (!controlsInitialized && typeof initializeControls === "function") {
        initializeControlsSafely();
      }
    }
  }, 2000);

  // Return cleanup function
  return () => {
    state.isDestroyed = true;
    clearInterval(bufferCheckInterval);
    clearTimeout(initTimeout);

    // Clean up event handlers
    Object.keys(eventHandlers).forEach(event => {
      try {
        player.off(event);
      } catch (e) {
        console.warn(`Failed to remove event listener: ${event}`, e);
      }
    });

    if (hlsTech) {
      ["appendbufferend", "loadedplaylist", "mediachange", "segmentdrop"
        // Add any other HLS-specific events
      ].forEach(event => {
        try {
          hlsTech.off(event);
        } catch (e) {
          console.warn(`Failed to remove HLS event listener: ${event}`, e);
        }
      });

      // Additional cleanup for HLS tech
      try {
        hlsTech.reset();
      } catch (e) {
        console.warn("Failed to reset HLS tech", e);
      }
    }

    // Clear any remaining timeouts
    if (player) {
      try {
        player.clearTimeout(); // Clear all timeouts registered through player
      } catch (e) {
        console.warn("Failed to clear player timeouts", e);
      }
    }
  };
};

export default initializeHLSPlayer;
