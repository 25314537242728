/* eslint-disable consistent-return */
/* eslint-disable func-names */
/* eslint-disable react/no-this-in-sfc */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState, useRef, useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import RecordRTC, { getSeekableBlob } from "recordrtc";
import { INITIAL_DURATION } from "configs/jobs/constants";
import {
  isMobile,
  isSafari,
  isMobileSafari,
  isIOS,
  isFirefox,
  isChrome,
  isEdge,
  isAndroid
} from "react-device-detect";
import { ref, set } from "firebase/database";

import {
  captureUserMedia,
  handleRecordVideoHTMLplayer,
  getVideoDeviceIds,
  isRetakePromptsEnabled,
  isAutoSaveBlobEnabled,
  NOT_ALLOWED_ERROR,
  PERMISSION_DENIED_ERROR,
  NOT_FOUND_ERROR,
  NOT_READABLE_ERROR,
  isRetakeSelectionOverlayEnabled
} from "mixins/helperVideoRecording";
import { clearFuncs, setDesktop } from "utils/videoClearer";
import useInterval from "hooks/useInterval";
import Countdown from "assets/audio/countdown.wav";
import * as Sentry from "@sentry/browser";
import { store } from "store";
import { fetchAllSavedAnswers, saveCurrentQuestionIsMuted, saveDeviceInfo, setAudioDevice, setVideoDevice } from "store/modules/сandidates/actions";
import { nanoid } from "nanoid";
import { isEmpty } from "lodash";
import { getIsStorageSupported } from "mixins/helperCandidate";
import FinalAttemptModal from "components/Modals/FinalAttemptModal/FinalAttemptModal";
import handlePlayFromRef from "utils/audio";
import { getCandidateToken } from "store/modules/сandidates/selectors";
import useInterviewStore from "store/interviewStore";
import { retry } from "mixins/helpers";
import RetakeOverlay from "./RetakeOverlay";

const headerHeight = 60;

export default WrappedComponent => {
  const Wrapped = props => {
    const {
      isLastQuestion,
      history,
      jobId,
      userId,
      questionNumber,
      question,
      question: {
        key: questionId = "",
        max_duration: maxDuration = INITIAL_DURATION,
        max_retakes: maxRetakes,
        attempt_number: attemptNumber = 0
      } = {},
      savedAnswers,
      saveIsAllAnswerSaved,
      saveCurrentQuestion,
      saveOnAmazonS3,
      clearErrors,
      retakeAnswer,
      isRecording,
      setIsRecording,
      setCashedElapsingTime,
      cashedElapsingTime,
      setUploadProgress,
      isStartTimer,
      setStartTimer,
      btnsDisabled,
      setBtnsDisabled,
      recorderTimeout,
      isShowDelayMessage,
      setShowDelayMessage,
      btnSetterTimeout,
      videoDeviceId,
      finishRecord,
      finishAndSaveRecord,
      db,
      onlyAudio,
      showErrorModal,
      uploadBlobToAmazonS3,
      isTestMode,
      displayAudioAnimation,
      hasAudio,
      fullVideoHasAudio,
      audioContextSupported,
      audioDeviceId,
      updateFirebaseData,
      hasUploadedMedia,
      ...rest
    } = props;
    const [cashedVideo, saveCashedVideo] = useState(null);
    const [blobUrl, setBlobUrl] = useState(null);
    const [isCompleted, setIsCompleted] = useState(false);
    const [isMediaBlocked, setIsMediaBlocked] = useState(false);
    const [isSuccessRecord, setSuccessRecord] = useState(true);
    const [isVideoPreview, setIsVideoPreview] = useState(false);
    const [streamId, setStreamId] = useState(null);
    const [videoDeviceIds, setVideoDeviceIds] = useState([]);
    const [showAttemptModal, setShowAttemptModal] = useState(false);
    const [audioDeviceIds, setAudioDeviceIds] = useState([]);
    const [showRetake, setShowRetake] = useState(false);

    const { setIsUploading } = useInterviewStore();

    const videoPlayer = useRef({});
    const streamRef = useRef(null);
    const recordRTCRef = useRef(null);
    const videoSourceRef = useRef(null);
    const audioSound = useMemo(() => new Audio(Countdown), []);
    const audio = useRef(isMobile ? null : audioSound);
    const showVideoRTC = onlyAudio ? audioDeviceId && audioDeviceIds.length > 0
      : videoDeviceId && videoDeviceIds.length > 0;


    const mimeTypes = [
      "video/webm;codecs=vp9",
      "video/webm;codecs=vp8",
      "video/webm;codecs=h264",
      "video/webm"
    ];

    const mimeType = useMemo(() => mimeTypes.find(type => MediaRecorder.isTypeSupported(type)) ?? "video/webm", []);

    const currentQuestionNumber = questionNumber + 1;

    const retakeAnswerRecord = () => {
      if (typeof retakeAnswer === "function") {
        retakeAnswer({ userId, jobId, questionId });
      }
    };

    const onRecordMediaSuccess = () => {
      setSuccessRecord(true);
      setIsMediaBlocked(false);

      retakeAnswerRecord();
    };

    const onRecordMediaSuccessPreview = () => {
      setSuccessRecord(true);
      setIsMediaBlocked(false);
    };

    const onMediaError = errorMessage => {
      setIsMediaBlocked(errorMessage);

      console.log({ errorMessage });

      setBtnsDisabled(state => ({
        ...state,
        btnLoading: false
      }));
    };

    const onRecordMediaError = errorMessage => {
      setSuccessRecord(false);
      onMediaError(errorMessage);
    };

    const getCamDevices = () => {
      setIsMediaBlocked(false);
      getVideoDeviceIds((devices, audioDevices) => {
        setVideoDeviceIds(devices?.filter(a => a.name !== "camera2 2, facing back" && a.name !== "camera2 3, facing front"));
        setAudioDeviceIds(audioDevices);
        const deviceInfo = {
          camera_device: JSON.stringify({
            cameraDevice: devices?.find(a => a.deviceId === videoDeviceId),
            mimeType
          }),
          microphone_device: JSON.stringify(audioDevices?.find(a => a.deviceId === audioDeviceId))
        };

        if (!videoDeviceId || !devices.map(a => a.deviceId).includes(videoDeviceId)) {
          deviceInfo.camera_device = JSON.stringify({
            cameraDevice: devices?.[0],
            mimeType
          });
          store.dispatch(setVideoDevice(devices?.[0]?.deviceId));
        }
        if (!audioDeviceId) {
          deviceInfo.microphone_device = JSON.stringify(audioDevices?.[0]);
          store.dispatch(setAudioDevice(audioDevices?.[0]?.deviceId));
        }

        store.dispatch(saveDeviceInfo({ userId, deviceInfo }));
        setDesktop(saveCashedVideo, setBtnsDisabled, setBlobUrl);
      }, onMediaError, onlyAudio);
    };

    useEffect(() => {
      let isMounted = true;

      if (isMounted) {
        isMounted = false;

        getCamDevices();
      }

      return () => {
        isMounted = true;

        if (streamRef && streamRef.current) {
          console.log("Removing tracks");
          streamRef.current.getTracks().forEach(track => {
            track.stop();
            streamRef.current.removeTrack(track);
          });
        }
      };
    }, []);

    useEffect(() => () => {
      if (videoPlayer && videoPlayer.current) {
        console.log("Removing video player");
        videoPlayer.current.removeAttribute("src");
        videoPlayer.current.load();
        videoPlayer.current.remove();
      }

      if (audio && audio.current) {
        console.log("Removing audio player");
        audio.current.removeAttribute("src");
        audio.current.load();
        audio.current.remove();
      }

      if (streamRef && streamRef.current) {
        console.log("Removing tracks");
        streamRef.current.getTracks().forEach(track => {
          track.stop();
          streamRef.current.removeTrack(track);
        });
      }

      if (recordRTCRef && recordRTCRef.current) {
        recordRTCRef.current.destroy();
      }

      clearFuncs();
    }, []);

    useEffect(() => {
      if (db && userId) {
        set(ref(db, `Candidates/${userId}/blobUrl`), blobUrl ? "blobUrl" : null);
      }
    }, [userId, db, blobUrl]);

    if (typeof navigator?.mediaDevices?.ondevicechange === "object" || typeof navigator?.mediaDevices?.ondevicechange === "function") {
      navigator.mediaDevices.ondevicechange = () => {
        getVideoDeviceIds((devices, audioDevices) => {
          setVideoDeviceIds(devices);
          setAudioDeviceIds(audioDevices);
          if (!devices.map(a => a.deviceId).includes(videoDeviceId)) {
            if (!(isRecording ||
                cashedVideo)) store.dispatch(setVideoDevice(devices?.[0]?.deviceId));
          }
          if (!audioDevices.map(a => a.deviceId).includes(audioDeviceId)) {
            if (!(isRecording ||
              cashedVideo)) store.dispatch(setAudioDevice(audioDevices?.[0]?.deviceId));
          }
        }, (errorMessage, nativeErr) => {
          if ([
            NOT_ALLOWED_ERROR,
            PERMISSION_DENIED_ERROR,
            NOT_FOUND_ERROR,
            NOT_READABLE_ERROR
          ].includes(nativeErr?.name)
          && errorMessage === "candidate.videoCreate.mediaBlocked") onMediaError(errorMessage);
        }, onlyAudio);
      };
    }

    useEffect(() => {
      const handler = e => {
        if ((isRetakePromptsEnabled && isAutoSaveBlobEnabled ? false : blobUrl) ||
          isRecording || isShowDelayMessage) {
          e.preventDefault();
          e.returnValue = "Changes you made may not be saved.";
        }
      };

      window.addEventListener("beforeunload", handler);

      return () => {
        window.removeEventListener("beforeunload", handler);
      };
    }, [blobUrl, isRecording, isShowDelayMessage]);

    // eslint-disable-next-line no-shadow
    const setStatusVideoComplete = (ref, src, blob, sourceRef, uniqueId = "") => {
      setBtnsDisabled(state => ({
        ...state,
        btnLoading: false
      }));

      let srcFile = src;
      if (!src?.includes("#t=0.001") && isRetakePromptsEnabled && src?.length) srcFile = `${src}#t=0.001?${!isEmpty(uniqueId) ? uniqueId : nanoid()}`;

      handleRecordVideoHTMLplayer({
        ref,
        volume: 1,
        src: srcFile,
        sourceRef
      });

      if (streamRef && streamRef.current) {
        const tracks = streamRef.current.getTracks();

        tracks.forEach(track => {
          track.stop();

          if (process.env.REACT_APP_CLOSE_ALL_STREAMS_AND_TRACKS === "true") streamRef.current.removeTrack(track);
        });

        if (streamRef) {
          streamRef.current.stop();
        }
      }

      setIsRecording(false);
      setIsCompleted(true);

      saveCashedVideo({ questionId, blob: blob || srcFile });

      if (isRetakePromptsEnabled) {
        setIsUploading(false);
      }
    };

    useInterval(
      () => setShowDelayMessage(isShowDelayMessage - 1),
      isShowDelayMessage ? 1000 : null
    );

    const errorStartVideoRecord = (error, callback) => {
      Sentry.captureException(error);
      if (typeof callback === "function") {
        callback(error);
      }
      setIsVideoPreview(true);
    };

    const getRecordRTCOptions = () => {
      if (onlyAudio) {
        return {
          type: "audio",
          checkForInactiveTracks: true,
          recorderType: RecordRTC.StereoAudioRecorder,
          numberOfAudioChannels: isEdge ? 1 : 2,
          bufferSize: 16384,
          ...(isSafari ? {
            sampleRate: 44100,
            bufferSize: 4096
          } : null)
        };
      }

      if (isFirefox) {
        return {
          type: "video",
          mimeType: "video/webm", // Let Firefox choose its preferred codecs
          disableLogs: false,
          recorderType: RecordRTC.MediaStreamRecorder,
          timeSlice: 1000,
          // Ensure stable recording on Firefox
          bitsPerSecond: 128000,
          frameInterval: 90,
          checking: true,
          numberOfAudioChannels: 1 // More stable on Firefox
        };
      }

      return {
        type: "video",
        mimeType
      };
    };

    const showVideoPreview = successCallback => {
      try {
        if (streamRef?.current) {
          streamRef.current.getTracks().forEach(track => {
            track.stop();
            streamRef.current.removeTrack(track);
          });
        }

        const start = async (stream, onSuccess) => {
          streamRef.current = stream;
          setStreamId(stream.id);

          const rtcOptions = getRecordRTCOptions();
          const videoRTCStream = await RecordRTC(stream, rtcOptions);

          recordRTCRef.current = videoRTCStream;

          if (videoPlayer.current) {
            videoPlayer.current.pause();
            videoPlayer.current.controls = false;
          }

          handleRecordVideoHTMLplayer({
            ref: videoPlayer,
            muted: true,
            autoplay: true,
            stream,
            src: null,
            videoRTCStream
          });

          if (isSafari && videoPlayer.current) {
            handlePlayFromRef(videoPlayer.current);
          }

          setIsVideoPreview(true);
          onSuccess();
          if (typeof successCallback === "function") {
            successCallback(stream);
          }
        };

        captureUserMedia(
          onlyAudio,
          videoDeviceId,
          audioDeviceId,
          stream => start(stream, onRecordMediaSuccessPreview),
          error => errorStartVideoRecord(error, onRecordMediaError),
          streamRef
        );
      } catch (error) {
        console.log("Error on showing preview video", error);
      }
    };
    const startVideoRecord = callback => {
      try {
        setShowDelayMessage(3);

        if (!isMobile) {
          handlePlayFromRef(audio.current);
        }

        recorderTimeout.current = setTimeout(() => {
          saveCashedVideo(null);
          setIsRecording(true);
          setIsCompleted(false);
          setIsVideoPreview(false);
          clearErrors();

          if (getIsStorageSupported()) window.sessionStorage.setItem("isRetriedAndReloaded", false);

          setShowDelayMessage(false);
          setCashedElapsingTime(maxDuration * 1000);
          setStartTimer(true);

          if (videoPlayer.current?.videoRTC) {
            videoPlayer.current.videoRTC.reset();
            videoPlayer.current.videoRTC.startRecording();
          }

          if (typeof callback === "function") {
            callback();
          }

          btnSetterTimeout.current = setTimeout(() => {
            setBtnsDisabled(state => ({
              ...state,
              btnStart: true,
              btnStop: false,
              btnLoading: false
            }));
          }, 3000);
        }, 3000);
      } catch (error) {
        console.log("Failed to start video record", error);
        Sentry.captureException(error?.message || error);
      }
    };

    const attempts = useMemo(() => {
      if (maxRetakes === 0 || maxRetakes === attemptNumber) return 1;

      if (maxRetakes === null) return null;

      return (maxRetakes - attemptNumber) + 1;
    }, [maxRetakes, attemptNumber]);

    const savedAnswer = useMemo(() => savedAnswers
      .find(answer => answer.question.key === questionId) || [], [savedAnswers]);

    const checkIsFinalAttempt = () => {
      if (!isRetakePromptsEnabled) return false;

      if (attempts <= 1 && maxRetakes !== null) {
        setShowAttemptModal(true);

        return true;
      }

      return false;
    };

    const continueRecord = async () => {
      try {
        if (videoPlayer.current) {
          videoPlayer.current.pause();
          videoPlayer.current.src = "";
        }
        setBlobUrl(null);

        if (isMobile && videoPlayer.current) {
          if (isIOS) {
            const scrollY =
              videoPlayer.current.getBoundingClientRect().top +
              window.scrollY -
              headerHeight;

            window.scrollTo({ top: scrollY, behavior: "smooth" });
          } else {
            videoPlayer.current.scrollIntoView();
          }
        }

        setBtnsDisabled(state => ({
          ...state,
          btnStart: true,
          btnStop: true,
          btnLoading: false
        }));

        setCashedElapsingTime(null);
        saveCashedVideo(null);

        if (
          !videoPlayer.current?.videoRTC ||
          videoPlayer.current?.videoRTC?.state !== "recording"
        ) {
          showVideoPreview(() => startVideoRecord(onRecordMediaSuccess));
        } else {
          startVideoRecord(onRecordMediaSuccess);
        }
      } catch (error) {
        console.log("Failed to to start record", error);
        Sentry.captureException(error?.message || error);
      }
    };

    const startRecord = () => {
      if (cashedVideo && !isTestMode && isRetakeSelectionOverlayEnabled && !isMobile) {
        return setShowRetake(true);
      }
      const isFinalAttempt = checkIsFinalAttempt();
      if (!isFinalAttempt) {
        continueRecord();
      }
    };

    const showLoadingBtn = () => {
      setBtnsDisabled(state => ({
        ...state,
        btnStart: false,
        btnStop: false,
        btnLoading: true
      }));
    };


    const successCallback = (seekableBlob, recordedBlob) => {
      if (typeof updateFirebaseData === "function") updateFirebaseData({ is_played: false });
      setStartTimer(false);

      const srcBlob = URL.createObjectURL(seekableBlob || recordedBlob);
      const link =
        onlyAudio || isMobileSafari || isFirefox || (isChrome && cashedVideo)
          ? srcBlob
          : `${srcBlob}#t=0.001`;

      setBlobUrl(link);

      const dataToUpload = {
        blob: recordedBlob,
        jobId,
        userId,
        questionId,
        questionNumber: currentQuestionNumber
      };

      saveCurrentQuestion({
        userId,
        questionCurrentCashed: dataToUpload
      });

      store.dispatch(saveCurrentQuestionIsMuted({
        userId,
        isMuted: audioContextSupported ? !fullVideoHasAudio : false
      }));

      if (isRetakePromptsEnabled && !isTestMode) {
        const callback = payload => {
          if (typeof payload !== "undefined") {
            const currentRemoteLink = payload?.remoteLink ?? recordedBlob;

            setStatusVideoComplete(
              videoPlayer, link, currentRemoteLink, payload?.data?.updated_at
            );
          }
        };

        uploadBlobToAmazonS3(recordedBlob, currentQuestionNumber, callback);
      } else {
        setStatusVideoComplete(videoPlayer, link, recordedBlob);
      }
    };

    const stopRecord = () => {
      try {
        return new Promise(() => {
          try {
            setBtnsDisabled(state => ({
              ...state,
              btnStart: false,
              btnStop: false
            }));

            if (videoPlayer.current && videoPlayer.current.videoRTC) {
              videoPlayer.current.videoRTC.stopRecording(async () => {
                try {
                  const recordedBlob = await videoPlayer?.current?.videoRTC?.getBlob();

                  if (recordedBlob?.size > 0) {
                    if (isSafari || isMobileSafari || isIOS || onlyAudio) {
                      successCallback(null, recordedBlob);
                      return;
                    }

                    try {
                      getSeekableBlob(
                        recordedBlob,
                        seekableBlob => successCallback(seekableBlob, recordedBlob)
                      );
                    } catch (err) {
                      console.log({ err });
                      console.log("Unable to get seekable blob");

                      successCallback(null, recordedBlob);
                      Sentry.captureException(`${err?.message || err} - EBML: ${typeof EBML !== "undefined"}`);
                    }
                  }
                } catch (e) {
                  console.log("Failed to stop record", e);
                  Sentry.captureException(`${e?.message || e} - EBML: ${typeof EBML !== "undefined"}`);

                  setBtnsDisabled(state => ({
                    ...state,
                    btnLoading: false,
                    btnStart: false,
                    btnStop: true
                  }));
                }
              });
            }

            setBtnsDisabled(state => ({
              ...state,
              btnStart: false,
              btnStop: true
            }));
          } catch (e) {
            console.log("Failed to stop record", e);
            Sentry.captureException(`${e?.message || e} - EBML: ${typeof EBML !== "undefined"}`);

            setBtnsDisabled(state => ({
              ...state,
              btnLoading: false,
              btnStart: false,
              btnStop: true
            }));
          }
        });
      } catch (e) {
        console.log("Failed to stop record", e);
        Sentry.captureException(`${e?.message || e} - EBML: ${typeof EBML !== "undefined"}`);

        setBtnsDisabled(state => ({
          ...state,
          btnLoading: false,
          btnStart: false,
          btnStop: true
        }));
      }

      return false;
    };

    const handleFinishRecord = (isAllowedEmptyBlob = false, isUploadErrorShown = false) => {
      try {
        const callbackFinish = () => {
          setIsRecording(false);
          setIsCompleted(false);
          setUploadProgress(0);
          setBtnsDisabled(state => ({
            ...state,
            btnLoading: false
          }));
        };

        const errorCallback = () => {
          setBtnsDisabled(state => ({
            ...state,
            btnLoading: false
          }));
        };

        if (typeof finishRecord === "function") {
          // eslint-disable-next-line max-len
          if (isRetakePromptsEnabled && (isIOS ? !isEmpty(savedAnswer?.remote_link) : true)) {
            finishAndSaveRecord(
              callbackFinish,
              currentQuestionNumber,
              errorCallback,
              isAllowedEmptyBlob,
              isUploadErrorShown
            );
          } else {
            // android devices uses this function to save the video
            finishRecord(
              cashedVideo?.blob,
              callbackFinish,
              currentQuestionNumber,
              isAllowedEmptyBlob,
              isUploadErrorShown
            );
          }
        } else {
          history.goBack();
          callbackFinish();
        }
      } catch (e) {
        console.log("Failed to handle finish record", e);
        showErrorModal();
      }
    };

    const resetVideoStatus = useCallback(() => {
      try {
        handleRecordVideoHTMLplayer({
          ref: videoPlayer,
          src: null
        });

        setIsRecording(false);
        setIsCompleted(false);
        saveCashedVideo(null);
      } catch (error) {
        console.log("Failed to reset video status", error);
        Sentry.captureException(error?.message || error);
      }
    }, [videoPlayer]);

    useEffect(() => {
      if (cashedElapsingTime === 0 || (cashedElapsingTime === 0 && isRecording)) {
        retry(stopRecord, showErrorModal);
      }
    }, [cashedElapsingTime, isRecording]);

    useEffect(() => {
      if (savedAnswers.length > 0) {
        const isCurrentQuestionSavedAnswer =
          savedAnswer?.question && savedAnswer?.question.key === questionId;

        if (isCurrentQuestionSavedAnswer) {
          if (videoSourceRef.current) {
            return setStatusVideoComplete(
              videoPlayer,
              savedAnswer?.remote_link,
              undefined,
              videoSourceRef,
              savedAnswer?.updated_at
            );
          }

          return setStatusVideoComplete(
            videoPlayer,
            savedAnswer?.remote_link,
            undefined,
            undefined,
            savedAnswer?.updated_at
          );
        }

        if (showVideoRTC) {
          showVideoPreview();
          return resetVideoStatus({ ref: videoPlayer });
        }
      }

      if (showVideoRTC) {
        showVideoPreview();
        return resetVideoStatus({ ref: videoPlayer });
      }
    }, [savedAnswers, questionId, savedAnswer, videoSourceRef,
      showVideoRTC, videoDeviceId, audioDeviceId]);

    // refetch answers if media was uploaded as per firebase
    // but not saved in redux savedAnswers for desktop
    // & local state cashedVideo for android devices
    const shouldRefetchAnswers = useMemo(() =>
      ((isAndroid ? !cashedVideo?.blob : !savedAnswer?.remote_link) && hasUploadedMedia),
    [savedAnswer?.remote_link, hasUploadedMedia, cashedVideo, isAndroid]);

    useEffect(() => {
      const candidateToken = getCandidateToken(store.getState());

      if (shouldRefetchAnswers && Boolean(userId) && Boolean(jobId) && Boolean(candidateToken)) {
        store.dispatch(
          fetchAllSavedAnswers({
            userId,
            jobId,
            candidateToken,
            shouldIncludeUnfinished: true
          })
        );
      }
    }, [shouldRefetchAnswers, userId, jobId]);

    return (
      <>
        {isRetakeSelectionOverlayEnabled ? (
          <RetakeOverlay
            showAttempt={attempts <= 1 && maxRetakes !== null && showRetake}
            onlyAudio={onlyAudio}
            open={showRetake}
            audioDevices={audioDeviceIds}
            videoDevices={videoDeviceIds}
            blobUrl={blobUrl}
            isRecording={isRecording}
            cashedVideo={cashedVideo}
            cashedElapsingTime={cashedElapsingTime}
            stopRecord={stopRecord}
            videoPlayerRef={videoPlayer}
            audioDeviceId={audioDeviceId}
            videoDeviceId={videoDeviceId}
            isShowDelayMessage={isShowDelayMessage}
            isVideoPreview={isVideoPreview}
            videoSourceRef={videoSourceRef}
            isMediaBlocked={isMediaBlocked}
            streamRef={streamRef}
            streamId={streamId}
            showLoadingBtn={showLoadingBtn}
            hasAudio={hasAudio}
            displayAudioAnimation={displayAudioAnimation}
            isTestMode={isTestMode}
            audioContextSupported={audioContextSupported}
            userId={userId}
            handleRetake={() => {
              continueRecord();
              setShowRetake(false);
              setShowAttemptModal(false);
            }}
            handleClose={() => {
              setShowRetake(false);
              setShowAttemptModal(false);
            }}
          />
        ) : null}
        <FinalAttemptModal
          show={showAttemptModal}
          handleOnHide={() => {
            setShowAttemptModal(false);
          }}
          handleOnContinue={() => {
            setShowAttemptModal(false);
            continueRecord();
          }}
          isOnlyAttempt={maxRetakes === 0}
        />
        <WrappedComponent
          hasAudio={hasAudio}
          clearTracks={showAttemptModal || showRetake || isRecording || isShowDelayMessage}
          displayAudioAnimation={displayAudioAnimation}
          isRecording={isRecording}
          isCompleted={isCompleted}
          isStartTimer={isStartTimer}
          question={question}
          videoPlayer={videoPlayer}
          blobUrl={blobUrl}
          btnsDisabled={btnsDisabled}
          cashedVideo={cashedVideo}
          cashedElapsingTime={cashedElapsingTime}
          isShowDelayMessage={isShowDelayMessage}
          startRecord={startRecord}
          stopRecord={stopRecord}
          finishRecord={handleFinishRecord}
          setCashedElapsingTime={setCashedElapsingTime}
          currentQuestionNumber={currentQuestionNumber}
          saveCashedVideo={saveCashedVideo}
          savedAnswers={savedAnswers}
          retakeAnswerRecord={retakeAnswerRecord}
          videoDeviceId={videoDeviceId}
          audioDevices={audioDeviceIds}
          audioDeviceId={audioDeviceId}
          videoDevices={videoDeviceIds}
          isMediaBlocked={isMediaBlocked}
          setIsMediaBlocked={setIsMediaBlocked}
          isSuccessRecord={isSuccessRecord}
          isVideoPreview={isVideoPreview}
          videoSourceRef={videoSourceRef}
          onlyAudio={onlyAudio}
          streamRef={streamRef}
          streamId={streamId}
          showErrorModal={showErrorModal}
          showLoadingBtn={showLoadingBtn}
          isTestMode={isTestMode}
          audioContextSupported={audioContextSupported}
          db={db}
          userId={userId}
          updateFirebaseData={updateFirebaseData}
          hasUploadedMedia={hasUploadedMedia}
          {...rest}
        />
      </>
    );
  };

  Wrapped.defaultProps = {
    isLastQuestion: undefined,
    history: undefined,
    jobId: undefined,
    userId: undefined,
    questionNumber: undefined,
    question: undefined,
    savedAnswers: [],
    saveOnAmazonS3: undefined,
    saveIsAllAnswerSaved: undefined,
    saveCurrentQuestion: undefined,
    saveVideoExtension: undefined,
    clearErrors: undefined,
    retakeAnswer: undefined,
    isRecording: undefined,
    setIsRecording: undefined,
    setCashedElapsingTime: undefined,
    cashedElapsingTime: undefined,
    videoDeviceId: undefined,
    isShowDelayMessage: undefined,
    db: undefined,
    uploadBlobToAmazonS3: undefined,
    isTestMode: undefined,
    updateFirebaseData: undefined
  };

  Wrapped.propTypes = {
    isLastQuestion: PropTypes.bool,
    history: PropTypes.shape({
      goBack: PropTypes.func,
      push: PropTypes.func,
      length: PropTypes.number
    }),
    jobId: PropTypes.string,
    userId: PropTypes.string,
    questionNumber: PropTypes.number,
    question: PropTypes.shape({
      key: PropTypes.string,
      text: PropTypes.string,
      max_duration: PropTypes.number,
      max_retakes: PropTypes.number,
      attempt_number: PropTypes.number
    }),
    savedAnswers: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string,
        remote_link: PropTypes.string
      })
    ),
    saveOnAmazonS3: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
    saveIsAllAnswerSaved: PropTypes.func,
    saveCurrentQuestion: PropTypes.func,
    saveVideoExtension: PropTypes.func,
    clearErrors: PropTypes.func,
    retakeAnswer: PropTypes.func,
    isRecording: PropTypes.bool,
    setIsRecording: PropTypes.func,
    setCashedElapsingTime: PropTypes.func,
    cashedElapsingTime: PropTypes.number,
    setUploadProgress: PropTypes.func.isRequired,
    isStartTimer: PropTypes.bool.isRequired,
    setStartTimer: PropTypes.func.isRequired,
    btnsDisabled: PropTypes.shape().isRequired,
    setBtnsDisabled: PropTypes.func.isRequired,
    recorderTimeout: PropTypes.shape().isRequired,
    setShowDelayMessage: PropTypes.func.isRequired,
    isShowDelayMessage: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
    btnSetterTimeout: PropTypes.shape().isRequired,
    videoDeviceId: PropTypes.string,
    finishRecord: PropTypes.func.isRequired,
    finishAndSaveRecord: PropTypes.func.isRequired,
    db: PropTypes.shape({}),
    onlyAudio: PropTypes.bool.isRequired,
    showErrorModal: PropTypes.func.isRequired,
    audioDeviceId: PropTypes.string.isRequired,
    uploadBlobToAmazonS3: PropTypes.func,
    isTestMode: PropTypes.bool,
    fullVideoHasAudio: PropTypes.bool.isRequired,
    hasAudio: PropTypes.bool.isRequired,
    displayAudioAnimation: PropTypes.bool.isRequired,
    audioContextSupported: PropTypes.bool.isRequired,
    updateFirebaseData: PropTypes.func
  };

  return Wrapped;
};
