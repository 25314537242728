import CandidateFileIcon from "assets/images/candidate/CandidateFileIcon";
import React from "react";

const CandidateThumbnailFile = ({ answer }) => (
  <>
    <CandidateFileIcon />
    <div
      className="candidatethumbnail__item__header-filechip"
      style={{
        marginTop: 15
      }}
    >
      {answer?.media_extension}
    </div>
  </>
);

export default CandidateThumbnailFile;
