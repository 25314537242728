/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable no-plusplus */
/* eslint-disable no-nested-ternary */
import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import { useDetectClickOutside } from "react-detect-click-outside";
import Mic from "assets/images/candidate/mic.png";
import Cam from "assets/images/candidate/video.png";
import { store } from "store";
import {
  saveDeviceInfo,
  setAudioDevice,
  setVideoDevice
} from "store/modules/сandidates/actions";
import {
  getAudioDevice,
  getVideoDevice
} from "store/modules/сandidates/selectors";
import { connect } from "react-redux";
import useDetectMute from "hooks/useDetectMute";
import Lottie from "react-lottie";
import animationData from "assets/images/candidate/wave.json";
import "./index.scss";
import { AUDIO_TYPE, VIDEO_TYPE } from "configs/jobs/constants";

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice"
  }
};

const RetakeOverlayDeviceSelect = ({
  audioDevices,
  onlyAudio,
  videoDevices,
  selectedAudioDevice,
  selectedVideoDevice,
  userId
}) => {
  const mimeTypes = [
    "video/webm;codecs=vp9",
    "video/webm;codecs=vp8",
    "video/webm;codecs=h264",
    "video/webm"
  ];

  const mimeType = useMemo(() => mimeTypes.find(type => MediaRecorder.isTypeSupported(type)) ?? "video/webm", [mimeTypes]);

  const { displayAudioAnimation, hasAudio } = useDetectMute(
    false,
    null,
    selectedAudioDevice
  );
  const [devicesListOpen, setDevicesListOpen] = useState(null);
  const audioDevicesRef = useDetectClickOutside({
    onTriggered: () => {
      if (devicesListOpen === AUDIO_TYPE) {
        setDevicesListOpen(null);
      }
    }
  });
  const videoDevicesRef = useDetectClickOutside({
    onTriggered: () => {
      if (devicesListOpen === VIDEO_TYPE) {
        setDevicesListOpen(null);
      }
    }
  });

  const sortedAudioDevices = [...(audioDevices ?? [])]?.sort((a, b) =>
    (a.deviceId === selectedAudioDevice
      ? -1
      : b.deviceId === selectedAudioDevice
        ? 1
        : 0));

  const sortedVideoDevices = [...(videoDevices ?? [])]?.sort((a, b) =>
    (a.deviceId === selectedVideoDevice
      ? -1
      : b.deviceId === selectedVideoDevice
        ? 1
        : 0));

  const audioDeviceLabel = audioDevices?.find(
    device => device.deviceId === selectedAudioDevice
  )?.name;

  const videoDeviceLabel = videoDevices?.find(
    device => device.deviceId === selectedVideoDevice
  )?.name;


  return (
    <div
      style={{
        marginTop: 20
      }}
    >
      <div style={{
        position: "relative"
      }}
      >
        {!onlyAudio && videoDevices?.length > 1 && (
          <div>
            <div
              className="retake-devices__audio-container"
              ref={videoDevicesRef}
              onClick={() => {
                setDevicesListOpen(prev => (!prev ? "video" : null));
              }}
            >
              <div
                className={`retake__switch ${
                videoDevices?.length > 1 ? "devices" : ""
                }`}
                onKeyDown={() => setDevicesListOpen(null)}
                role="button"
                tabIndex={0}
              >
                <img src={Cam} alt="cam" style={{ height: 15 }} />
                <div className="retake-overlay__label">
                  {videoDeviceLabel || "Select Camera"}
                </div>
              </div>
            </div>

            {devicesListOpen === "video" && (
              <div className="retake__video__camera-devices">
                {sortedVideoDevices?.map(videoDevice => (
                  <span
                    className={
                      selectedVideoDevice === videoDevice?.deviceId
                        ? "video__camera-device active"
                        : "video__camera-device"
                    }
                    onClick={() => {
                      store.dispatch(setVideoDevice(videoDevice?.deviceId));
                      store.dispatch(saveDeviceInfo({
                        userId,
                        deviceInfo: { camera_device: JSON.stringify({
                          videoDevice,
                          mimeType
                        }) }
                      }));
                      setDevicesListOpen(null);
                    }}
                    onKeyDown={() => {
                      store.dispatch(setVideoDevice(videoDevice?.deviceId));
                      store.dispatch(saveDeviceInfo({
                        userId,
                        deviceInfo: { camera_device: JSON.stringify({ videoDevice, mimeType }) }
                      }));
                      setDevicesListOpen(null);
                    }}
                    role="button"
                    tabIndex={0}
                    key={videoDevice?.deviceId}
                  >
                    {videoDevice?.name}
                  </span>
                ))}
              </div>
            )}
          </div>
        )}
        {audioDevices?.length > 1 && (
          <div style={{
            position: "relative"
          }}
          >
            <div
              className="retake-devices__audio-container"
              ref={audioDevicesRef}
              onClick={() => {
                setDevicesListOpen(prev => (!prev ? "audio" : null));
              }}
            >
              {displayAudioAnimation ? (
                <Lottie
                  options={defaultOptions}
                  style={{
                    position: "absolute",
                    left: 0,
                    height: 45,
                    width: "100%",
                    top: 0,
                    borderRadius: 20
                  }}
                  speed={0.5}
                />
              ) : (
                <div
                  style={{
                    backgroundColor: "#aeaad2",
                    position: "absolute",
                    left: 0,
                    height: 10,
                    width: "100%",
                    bottom: 0,
                    borderRadius: 20
                  }}
                />
              )}
              <div
                className={`retake__switch ${
                  audioDevices?.length > 1 ? "devices" : ""
                }`}
                onKeyDown={() => setDevicesListOpen(null)}
                role="button"
                tabIndex={0}
              >
                <img src={Mic} alt="mic" style={{ height: 15 }} />
                <div className="retake-overlay__label">
                  {audioDeviceLabel || "Select Microphone"}
                </div>
                {!hasAudio && (
                  <div className="retake-overlay__no-audio">
                    <div
                      style={{
                        backgroundColor: "#f47703",
                        borderRadius: 500,
                        padding: 2
                      }}
                    >
                      <div
                        style={{
                          backgroundColor: "#ae4c4a",
                          width: 6,
                          height: 6,
                          borderRadius: 500
                        }}
                      />
                    </div>
                    No audio
                  </div>
                )}
              </div>
            </div>

            {devicesListOpen === "audio" && (
              <div
                className="retake__video__camera-devices"
                style={{
                  bottom: -144
                }}
              >
                {sortedAudioDevices?.map(audioDevice => (
                  <span
                    className={
                      selectedAudioDevice === audioDevice?.deviceId
                        ? "video__camera-device active"
                        : "video__camera-device"
                    }
                    onClick={() => {
                      store.dispatch(setAudioDevice(audioDevice?.deviceId));
                      store.dispatch(saveDeviceInfo({
                        userId,
                        deviceInfo: { microphone_device: JSON.stringify(audioDevice) }
                      }));
                      setDevicesListOpen(null);
                    }}
                    onKeyDown={() => {
                      store.dispatch(setAudioDevice(audioDevice?.deviceId));
                      store.dispatch(saveDeviceInfo({
                        userId,
                        deviceInfo: { microphone_device: JSON.stringify(audioDevice) }
                      }));
                      setDevicesListOpen(null);
                    }}
                    role="button"
                    tabIndex={0}
                    key={audioDevice?.deviceId}
                  >
                    {audioDevice?.name}
                  </span>
                ))}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
RetakeOverlayDeviceSelect.propTypes = {
  audioDevices: PropTypes.arrayOf(
    PropTypes.shape({
      deviceId: PropTypes.string,
      name: PropTypes.string
    })
  ).isRequired,
  onlyAudio: PropTypes.bool.isRequired,
  selectedVideoDevice: PropTypes.string.isRequired,
  selectedAudioDevice: PropTypes.string.isRequired,
  videoDevices: PropTypes.arrayOf(
    PropTypes.shape({
      deviceId: PropTypes.string,
      name: PropTypes.string
    })
  ).isRequired,
  userId: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
  selectedAudioDevice: getAudioDevice(state),
  selectedVideoDevice: getVideoDevice(state)
});

export default connect(mapStateToProps)(RetakeOverlayDeviceSelect);
