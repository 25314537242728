/* eslint-disable no-unused-expressions */
/* eslint-disable react/prop-types */
import React, { useMemo } from "react";
import { withTranslation } from "react-i18next";
import classNames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Container, Row, Col } from "react-bootstrap";

import LanguageDropdown from "containers/Common/DropdownLanguage";
import { getToken } from "store/modules/auth/selectors";
import {
  getFontColorFromBgColor,
  getWilloLogoFromBgColor
} from "mixins/helperDynamicStyles";
import styled from "styled-components";
import { getCandidateJobInfo } from "store/modules/сandidates/selectors";
import { store } from "store";

const StyledLink = styled("a")`
  color: ${props => props.color} !important;
`;

const Footer = ({
  t,
  company,
  isPublic,
  isAuthenticated,
  isBrandingHidden
}) => {
  function hexToRgb(hex) {
    if (!hex) return "";
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return `rgba(${parseInt(result[1], 16)}, ${parseInt(
      result[2],
      16
    )}, ${parseInt(result[3], 16)}, 0.75)`;
  }

  const fontColor = getFontColorFromBgColor({ hex: company?.layout_color });
  const footerLogo = getWilloLogoFromBgColor(company?.layout_color, "footer");

  const commonJobInfo = getCandidateJobInfo(store.getState());

  const isWilloBrandingHidden = useMemo(() => {
    if (typeof isBrandingHidden === "undefined") return commonJobInfo?.allow_white_labeling;

    return isBrandingHidden;
  }, [commonJobInfo, isBrandingHidden]);

  return (
    <div
      className="footer__wrapper w-full flex"
      style={company && { backgroundColor: hexToRgb(company.layout_color) }}
    >
      <Container>
        <Row>
          <Col
            className={classNames("footer__column", "footer__column_left")}
            lg={4}
            xl={4}
          >
            {!isWilloBrandingHidden ? (
              <div className="footer__block">
                <a
                  className="footer__image-wrapper"
                  href="https://willotalent.com/?utm_source=internal&utm_medium=user&utm_campaign=footer"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img className="footer__logo" src={footerLogo} alt="logo" />
                </a>
                <p className="footer__text" style={{ color: fontColor }}>
                  <span>
                    {`${t(
                      "footer.willo"
                    )} ${new Date().getFullYear()}`}

                  </span>
                  <br />
                  <span>{`${t("footer.register")} SC601085`}</span>
                </p>
              </div>
            ) : null}
          </Col>
          <Col
            className={classNames("footer__column", "footer__column_center")}
            lg={8}
            xl={8}
          >
            <ul className="footer__list">
              {!isPublic && isAuthenticated ? (
                <li>
                  <StyledLink
                    href="https://willo.getrewardful.com/signup"
                    target="_blank"
                    rel="noopener noreferrer"
                    color={fontColor}
                  >
                    {t("link.refer")}
                  </StyledLink>
                </li>
              ) : null}
              <li>
                <StyledLink
                  href="https://support.willo.video"
                  target="_blank"
                  rel="noopener noreferrer"
                  color={fontColor}
                >
                  {t("link.support")}
                </StyledLink>
              </li>

              <li>
                <StyledLink
                  href="https://support.willo.video/category/17-privacy-and-data"
                  target="_blank"
                  rel="noopener noreferrer"
                  color={fontColor}
                >
                  {t("link.privacy")}
                </StyledLink>
              </li>
              <li>
                <LanguageDropdown
                  isPublic={isPublic}
                  brandingColor={company?.layout_color}
                />
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

Footer.defaultProps = {
  company: undefined,
  isBrandingHidden: undefined
};

Footer.propTypes = {
  t: PropTypes.func.isRequired,
  company: PropTypes.shape({
    layout_color: PropTypes.string
  }),
  isBrandingHidden: PropTypes.bool
};

const mapStateToProps = state => ({
  isAuthenticated: Boolean(getToken(state)),
  isBrandingHidden: getCandidateJobInfo(state)?.allow_white_labeling
});

export default connect(mapStateToProps)(withTranslation()(Footer));
