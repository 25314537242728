import React, { useEffect, useMemo, useState } from "react";
import { ANSWER_STATUS, AUDIO_TYPE } from "configs/jobs/constants";
import { isNull } from "lodash";
import { isChrome, isIOS, isMobile, isMobileSafari, osVersion } from "react-device-detect";
import { BeatLoader } from "react-spinners";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { nanoid } from "nanoid";
import { shouldLoadM3u8 } from "mixins/helperVideoRecording";
import srcPoster from "assets/images/video/poster.jpg";
import { getCandidateAnswers } from "store/modules/jobs/selectors";
import { store } from "store";
import { setCurrentQuestionAnswer } from "store/modules/jobs/actions";
import useMediaPlayerStore from "store/mediaPlayerStore";
import CandidateAnswerMediaPlayer from "./CandidateAnswerMediaPlayer";
import CandidateAnswerPreview from "../../CandidateAnswerPreview";

const CandidateAnswerMedia = ({
  t,
  candidateAnswers,
  currentQuestionAnswer: {
    key,
    status,
    media_extension: mediaExtension,
    playlist_remote_link: playlistRemoteLink,
    remote_link: remoteLink,
    updated_at: updatedAt,
    question,
    thumbnail_remote_link: thumbnailRemoteLink
  } = {},
  handleGoToScorecard,
  autoPlay,
  setAutoPlay
}) => {
  const [videoErrors, setVideoErrors] = useState(null);
  const [settings, setSettings] = useState({
    volume: 1,
    playbackRate: 1
  });
  const { timePlaying, setTimePlaying } = useMediaPlayerStore();
  const [times, setTimes] = useState({});
  const [isHovering, setIsHovering] = useState(false);
  const [animated, setAnimated] = useState(true);

  const isProcessing = [ANSWER_STATUS.processing].includes(status);
  const isFailed = [ANSWER_STATUS.failed].includes(status);
  const isUploadInterrupted = isNull(mediaExtension);
  const allowedExtensionsOnIOS = ["mp4", "m4v", "mov", "wav", "mp3"].includes(
    mediaExtension?.toLowerCase()
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onlyAudio = useMemo(() => question?.answer_type === AUDIO_TYPE, [question?.answer_type]);

  const showScorecardButton = false;

  const m3u8 = {
    src:
      playlistRemoteLink &&
      `${playlistRemoteLink}#t=0.001?${updatedAt}`,
    type: "application/x-mpegURL"
  };

  const mp4 = {
    src: `${remoteLink}#t=0.001?${updatedAt}`,
    type: "video/mp4"
  };

  let videoSource = [m3u8, mp4];

  if (shouldLoadM3u8 && playlistRemoteLink) videoSource = [m3u8];

  // No support for m3u8 on iOS 17.4, 17.6.1 and 18.0
  if ((
    isIOS && parseFloat(osVersion) >= 17.4 &&
      parseFloat(osVersion) <= 17.5 &&
      shouldLoadM3u8) ||
      (shouldLoadM3u8 && !playlistRemoteLink) ||
      (isIOS && parseFloat(osVersion) >= parseFloat("17.6.1") &&
      parseFloat(osVersion) <= parseFloat(18.0))) videoSource = [mp4];

  const downloadSource = [
    {
      src: `${remoteLink}#t=0.001?${updatedAt}`,
      type: "video/mp4"
    }
  ];

  const audioSource = [
    {
      src: `${remoteLink}#${
        updatedAt
      }${nanoid()}`,
      type: "audio/mp3"
    }
  ];

  let shouldShowProcessing = false;

  if (isIOS) {
    shouldShowProcessing = ["wav", "mov"].includes(
      mediaExtension?.toLowerCase()
    )
      ? false
      : !allowedExtensionsOnIOS || isProcessing;
  }

  if (isFailed) {
    shouldShowProcessing = false;
  }

  const shouldAutoPlay = useMemo(() => parseFloat(timePlaying[key]?.remaining) < 6.1
    && parseFloat(timePlaying[key]?.time) > 0, [key, timePlaying]);

  const nextAnswer = useMemo(() => candidateAnswers?.find(
    answer => answer?.question?.order === question?.order + 1
  ), [candidateAnswers, question.order]);

  const previousAnswer = useMemo(() => candidateAnswers?.find(
    answer => (question?.order > 1 ? answer?.question?.order === question?.order - 1 : 1)
  ), [candidateAnswers, question.order]);

  const handleNextQuestion = () => {
    setTimes({});
    setTimePlaying({});
    setAutoPlay(true);
    store.dispatch(setCurrentQuestionAnswer(nextAnswer));
  };

  const handlePreviousQuestion = () => {
    setTimes({});
    setTimePlaying({});
    setAutoPlay(true);
    store.dispatch(setCurrentQuestionAnswer(previousAnswer));
  };

  // eslint-disable-next-line arrow-body-style
  useEffect(() => {
    return () => {
      setIsHovering(false);
    };
  }, []);

  const videoJsOptions = {
    autoplay: autoPlay,
    playbackRates: [0.8, 1, 1.2, 1.5, 1.75, 2],
    fluid: onlyAudio,
    responsive: true,
    controls: true,
    controlsList: "nodownload",
    controlBar: {
      remainingTimeDisplay: false,
      timeDivider: false
    },
    // eslint-disable-next-line no-nested-ternary
    poster: thumbnailRemoteLink?.length
      ? thumbnailRemoteLink
      : (isMobileSafari || (isChrome && isIOS))
        ? srcPoster : undefined,
    className: "video__preview-item-player",
    sources: onlyAudio ? audioSource : videoSource,
    withDownload: false,
    questionNumber: question.order,
    questionKey: question.key,
    playsinline: true,
    inactivityTimeout: 0,
    onReady: () => {},
    withError: true,
    settings,
    setSettings: isMobile ? null : setSettings,
    currentTime: times?.[key],
    setCurrentTime: ({ time, duration, remaining }) => {
      setTimes({ ...timePlaying, [key]: time });
      setTimePlaying({ ...timePlaying, [key]: { time, duration, remaining } });
    },
    mediaExtension,
    withNext: !!nextAnswer,
    withPrevious: true,
    ...(onlyAudio
      ? null
      : {
        crossOrigin: isIOS ? "anonymous" : "use-credentials"
      })
  };

  return (
    <div key={`answer-${String(key)}`}>
      {shouldShowProcessing || isUploadInterrupted ? (
        <div className="videojs__player-container v2">
          <div className="processing-wrapper">
            <span className="processing-wrapper__text">
              {
                isUploadInterrupted ? t("errors.interruptedVideoUpload.0") : "Media still processing."
              }
              <br />
              {
                isUploadInterrupted ? t("errors.interruptedVideoUpload.1") : "Please check back soon"
              }
            </span>
            { !isUploadInterrupted && <BeatLoader color="#4a4a4a" /> }
          </div>
        </div>
      ) : (
      // eslint-disable-next-line max-len
        <CandidateAnswerMediaPlayer
          handleGoToScorecard={handleGoToScorecard}
          downloadSource={downloadSource}
          {...videoJsOptions}
          onlyAudio={onlyAudio}
          isCandidate={false}
          videoErrors={videoErrors}
          setVideoErrors={setVideoErrors}
          showScorecardButton={showScorecardButton}
          handleNextQuestion={handleNextQuestion}
          handlePreviousQuestion={handlePreviousQuestion}
          showPreview={animated}
          setShowPreview={setAnimated}
          thumbnailRemoteLink={thumbnailRemoteLink}
        />
      )}

      {shouldAutoPlay && nextAnswer ? (
        <div
          onMouseEnter={() => setIsHovering(true)}
          onMouseLeave={() => setIsHovering(false)}
        >
          <CandidateAnswerPreview
            handleNextQuestion={handleNextQuestion}
            isHovering={isHovering}
            animated={animated}
            setAnimated={setAnimated}
            setIsHovering={setIsHovering}
            nextAnswer={nextAnswer}
          />
        </div>
      ) : null}
    </div>
  );
};

CandidateAnswerMedia.defaultProps = {
  t: undefined,
  candidateAnswers: [],
  currentQuestionAnswer: {},
  handleGoToScorecard: () => {},
  timePlaying: {},
  setTimePlaying: () => {}
};

CandidateAnswerMedia.propTypes = {
  t: PropTypes.func,
  candidateAnswers: PropTypes.arrayOf(PropTypes.shape({
    question: PropTypes.shape({
      order: PropTypes.number
    })
  })),
  currentQuestionAnswer: PropTypes.shape({
    media_extension: PropTypes.string,
    status: PropTypes.string
  }),
  handleGoToScorecard: PropTypes.func,
  timePlaying: PropTypes.shape({
    remaining: PropTypes.number
  }),
  setTimePlaying: PropTypes.func
};

const mapStateToProps = state => ({
  candidateAnswers: getCandidateAnswers(state),
  currentQuestionAnswer: state?.jobsCandidate?.currentQuestionAnswer
});

export default connect(mapStateToProps)(withTranslation()(CandidateAnswerMedia));
