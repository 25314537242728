/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useRef } from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import ReactHtmlParser from "react-html-parser";

import { Reload } from "mixins/svgIcons";
import AnswerHeader from "components/Common/AnswerHeader";

import "./styles.scss";
import DOMPurify from "dompurify";
import { getIsJobCandidateLoading } from "store/modules/jobs/selectors";
import SkeletonWrapper from "containers/Hoc/SkeletonWrapper";
import { isPublicInfoLoading } from "store/modules/public/selectors";
import { connect } from "react-redux";
import { getBorderColorContrastOrHex, getColorContrastOrHex } from "mixins/helperDynamicStyles";

const Item = ({
  t,
  history,
  jobId,
  userId,
  question,
  questionNumber,
  withButton,
  size,
  textSize,
  retakeAnswerAfterFileUpload,
  answer,
  questionTextProps,
  memorizedCompanyInfo,
  thinkingTime,
  spentTime,
  currentQuestion,
  isJobCandidateLoading,
  isShowcaseLoading
}) => {
  const ref = useRef(null);
  const returnCreateQuestion = useCallback(() => {
    retakeAnswerAfterFileUpload({ userId, questionKey: question.key });

    history.push({
      pathname: `/candidate/video-questions/create/${userId}/${jobId}/${question.key}`
    });
  }, []);

  const handleSelectText = () => {
    const selection = window.getSelection();
    const range = document.createRange();
    range.setStartBefore(ref.current);
    range.setEndAfter(ref.current);
    selection.removeAllRanges();
    selection.addRange(range);
  };

  function transform(node) {
    if (node.type === "tag") {
      node.attribs = {
        ...node.attribs,
        target: "_blank"
      };
    }
  }

  return (
    <div className="video__preview-item">
      <AnswerHeader
        questionNumber={questionNumber}
        thinkingTime={thinkingTime}
        spentTime={spentTime}
        textSize={textSize}
        questionTextProps={questionTextProps}
        questionText={question?.text}
        currentQuestion={currentQuestion}
      />

      <SkeletonWrapper
        skeletonProps={{
          count: 1,
          enableAnimation: true,
          height: "30vh",
          borderRadius: 20
        }}
        skeletonWrapperProps={{
          style: {
            display: "block",
            lineHeight: 1.5,
            marginBottom: "0.5rem",
            width: "100%"
          }
        }}
        isLoading={isJobCandidateLoading || isShowcaseLoading}
      >
        <>
          <div className="text__answer-scroll" onDoubleClick={handleSelectText}>
            <div
              className={`text__answer ${size}`}
              ref={ref}
              id="text__answers"
            >
              {ReactHtmlParser(
                DOMPurify.sanitize(answer?.html_text, {
                  ADD_ATTR: "href"
                }) ??
                  "<p>Response missing. If this is unexpected, please contact support@willo.video</p>",
                {
                  transform
                }
              )}
            </div>
          </div>

          {withButton && (
            <div className="video__preview-button">
              <button
                type="button"
                className="
                  n-button__large-border
                  n-border-purple-100
                  n-purple-100
                  flex flex-row items-center
                "
                onClick={returnCreateQuestion}
                style={
                  memorizedCompanyInfo && {
                    borderColor: getBorderColorContrastOrHex({
                      hex: memorizedCompanyInfo.button_color
                    }),
                    color: getColorContrastOrHex({
                      hex: memorizedCompanyInfo.button_color
                    })
                  }
                }
              >
                <Reload
                  svgIconClass="n-icon__large right"
                  fill={getColorContrastOrHex({ hex: memorizedCompanyInfo.button_color })}
                />
                {t("button.edit")}
              </button>
            </div>
          )}
        </>
      </SkeletonWrapper>
    </div>
  );
};

Item.defaultProps = {
  history: null,
  jobId: "",
  userId: "",
  question: {},
  questionNumber: "",
  withButton: true,
  size: "",
  textSize: "",
  retakeAnswerAfterFileUpload: null,
  answer: {},
  questionTextProps: null,
  memorizedCompanyInfo: {},
  thinkingTime: 0,
  spentTime: 0
};

Item.propTypes = {
  t: PropTypes.func.isRequired,
  history: PropTypes.shape({
    length: PropTypes.number,
    push: PropTypes.func
  }),
  jobId: PropTypes.string,
  userId: PropTypes.string,
  question: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.number,
    text: PropTypes.string,
    key: PropTypes.string
  }),
  questionNumber: PropTypes.number,
  withButton: PropTypes.bool,
  size: PropTypes.string,
  textSize: PropTypes.string,
  retakeAnswerAfterFileUpload: PropTypes.func,
  answer: PropTypes.shape({
    html_text: PropTypes.string,
    text: PropTypes.string
  }),
  questionTextProps: PropTypes.shape({}),
  memorizedCompanyInfo: PropTypes.shape({
    button_color: PropTypes.string
  }),
  thinkingTime: PropTypes.number,
  spentTime: PropTypes.number,
  currentQuestion: PropTypes.shape({
    question: PropTypes.shape({
      html_text: PropTypes.string
    })
  }).isRequired
};

const mapStateToProps = state => ({
  isJobCandidateLoading: getIsJobCandidateLoading(state),
  isShowcaseLoading: isPublicInfoLoading(state)
});

export default connect(mapStateToProps)(withTranslation()(Item));
