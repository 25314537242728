import React, { useEffect } from "react";
import PropTypes from "prop-types";
import debounce from "lodash/debounce";

import { ChevronLeft } from "mixins/svgIcons";
import useWindowResize from "hooks/useWindowResize.tsx";

const ArrowButtonLeft = ({
  currentIndex,
  nextIndex,
  items,
  setSelected
}) => {
  const { isMobileAndTabletScreen } = useWindowResize();

  const prevIndex = Math.max(currentIndex - 1, 0);

  const handleKeyDown = debounce(e => {
    // Check if any input element is focused
    const { activeElement } = document;
    const isInputFocused = activeElement.tagName.toLowerCase() === "input";


    if (!items.length) return;

    // Only handle arrow keys when no input is focused or when cmd/ctrl isn't pressed
    if (!isInputFocused) {
      if (e.keyCode === 37) {
        setSelected(items[prevIndex]?.key);
      } else if (e.keyCode === 39) {
        setSelected(items[nextIndex]?.key);
      }
    }
  }, 500);

  useEffect(() => {
    const handleKeyDownWithCapture = e => {
      // Prevent cmd + arrow key navigation when input is focused
      const { activeElement } = document;
      const isInputFocused = activeElement.tagName.toLowerCase() === "input";

      if (isInputFocused && (e.metaKey || e.ctrlKey) &&
          (e.keyCode === 37 || e.keyCode === 39)) {
        e.preventDefault();
        e.stopPropagation();
      }
    };

    // Add capture phase event listener for cmd + arrow prevention
    window.addEventListener("keydown", handleKeyDownWithCapture, true);
    // Regular event listener for arrow navigation
    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDownWithCapture, true);
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleKeyDown]);

  return (
    <>
      <button
        type="button"
        className="button__without-styles arrow-button left"
        onClick={() => setSelected(items[prevIndex].key)}
        disabled={[0, -1].includes(currentIndex)}
        title={currentIndex === 0 ? null : "prev"}
        aria-label="Previous"
      >
        <ChevronLeft
          fill={[0, -1].includes(currentIndex) ? "#59595950" : "#595959"}
          height={isMobileAndTabletScreen ? "20" : "24"}
          width={isMobileAndTabletScreen ? "20" : "24"}
        />
      </button>
    </>
  );
};

ArrowButtonLeft.defaultProps = {
  items: [],
  setSelected: null
};

ArrowButtonLeft.propTypes = {
  currentIndex: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  nextIndex: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  items: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string
  })),
  setSelected: PropTypes.func
};

export default ArrowButtonLeft;
