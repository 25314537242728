/* eslint-disable no-mixed-operators */
/* eslint-disable radix */
/* eslint-disable no-multi-assign */
/* eslint-disable no-restricted-globals */
import * as Sentry from "@sentry/browser";
import { isEmpty } from "lodash";
import moment from "moment";
import {
  isMobileSafari,
  isMobileOnly,
  isTablet,
  isChrome,
  isEdge,
  isMobile,
  isIOS
} from "react-device-detect";

const sendSentryError = error => {
  Sentry.captureException(error?.message || error);
};

export const VIDEO_CONSTRAINTS = {
  width: { ideal: 1280 },
  height: { ideal: 720 },
  frameRate: { ideal: 25, max: 30 },
  aspectRatio: { ideal: 16 / 9 }
};

const nativeError = "candidate.videoCreate.nativeError";
const videoError = "candidate.videoCreate.mediaBlocked";
const nativeErrorAudio = "candidate.videoCreate.nativeErrorAudio";
const videoErrorAudio = "candidate.videoCreate.mediaBlockedAudio";

export const captureUserMedia = (
  onlyAudio,
  videoDeviceId,
  audioDeviceId,
  successCallback,
  errorCallback,
  streamRef
) =>
  typeof navigator?.mediaDevices?.getUserMedia === "function" &&
  navigator.mediaDevices
    .getUserMedia(
      onlyAudio
        ? {
          audio: isEdge
            ? { deviceId: audioDeviceId }
            : {
              echoCancellation: false,
              deviceId: audioDeviceId
            }
        }
        : {
          video: { ...VIDEO_CONSTRAINTS, deviceId: videoDeviceId },
          audio: { deviceId: audioDeviceId } // Removed the 'exact' property
        }
    )
    ?.then(
      prevStream => {
        prevStream.getTracks().forEach(track => {
          track.stop();

          if (process.env.REACT_APP_CLOSE_ALL_STREAMS_AND_TRACKS === "true") prevStream.removeTrack(track);
        });
        return navigator.mediaDevices
          .getUserMedia(
            onlyAudio
              ? {
                audio: isEdge
                  ? { deviceId: audioDeviceId }
                  : {
                    echoCancellation: false,
                    deviceId: audioDeviceId
                  }
              }
              : {
                video: { ...VIDEO_CONSTRAINTS, deviceId: videoDeviceId },
                audio: { deviceId: audioDeviceId } // Removed the 'exact' property
              }
          )
          ?.then(
            stream => {
              if (streamRef?.current) {
                const tracks = streamRef.current.getTracks();

                tracks.forEach(track => {
                  track.stop();

                  if (process.env.REACT_APP_CLOSE_ALL_STREAMS_AND_TRACKS === "true") streamRef.current.removeTrack(track);
                });
              }
              if (stream && typeof successCallback === "function") {
                successCallback(stream);
              }

              return stream;
            },
            error => {
              if (error && typeof errorCallback === "function" && !isEmpty(error)) {
                errorCallback(onlyAudio ? videoErrorAudio : videoError);
              }
              if (error) sendSentryError(error);
              return error;
            }
          )
          .catch(nativeErr => {
            console.log("[nativeErr]", nativeErr);

            if (nativeErr && typeof errorCallback === "function" && !isEmpty(nativeErr)) {
              errorCallback(onlyAudio ? nativeErrorAudio : nativeError);
            }
            if (nativeErr) sendSentryError(nativeErr);
          });
      },
      error => {
        if (error && typeof errorCallback === "function" && !isEmpty(error)) {
          errorCallback(onlyAudio ? videoErrorAudio : videoError);
        }
        if (error) sendSentryError(error);
        return error;
      }
    )
    .catch(nativeErr => {
      console.log("[nativeErr]", nativeErr);

      if (nativeErr && typeof errorCallback === "function" && !isEmpty(nativeErr)) {
        errorCallback(onlyAudio ? nativeErrorAudio : nativeError);
      }
      if (nativeErr) sendSentryError(nativeErr);
    });

export const getVideoDeviceIds = (successCallback, errorCallback, onlyAudio) =>
  // Need to do this to ensure the browser asks for permission...
  typeof navigator?.mediaDevices?.getUserMedia === "function" &&
  navigator.mediaDevices
    .getUserMedia({ audio: true, ...(onlyAudio ? null : { video: true }) })
    ?.then(stream =>
      navigator.mediaDevices.enumerateDevices()?.then(mediaDevices => {
        stream.getTracks().forEach(track => {
          track.stop();
        });

        const micDevices = [];
        const camDevices = [];

        mediaDevices.forEach(mediaDevice => {
          if (mediaDevice?.kind === "audioinput") {
            micDevices.push({
              deviceId: mediaDevice?.deviceId,
              name: (mediaDevice?.label || "").replace("(Built-in)", "")
            });
          }
          if (mediaDevice?.kind === "videoinput") {
            camDevices.push({
              deviceId: mediaDevice?.deviceId,
              name: (mediaDevice?.label || "").replace("(Built-in)", "")
            });
          }
        });

        successCallback(camDevices, micDevices);
      }))
    .catch(nativeErr => {
      console.log("[nativeErr]", nativeErr);
      if (nativeErr) sendSentryError(nativeErr);
      if (errorCallback) {
        errorCallback(onlyAudio ? videoErrorAudio : videoError, nativeErr);
      }
    });

export const formTimeString = secondsInput => {
  const secondsDisplay = `0${Math.floor(secondsInput / 1000) % 60}`.slice(-2);
  const minutesDisplay = `0${Math.floor(secondsInput / 60000) % 60}`.slice(-2);

  return `${minutesDisplay}:${secondsDisplay}`;
};

export const spentTimeString = s => {
  const secs = s % 60;
  s = (s - secs) / 60;
  const mins = s % 60;
  const hrs = (s - mins) / 60;
  const hrsDay = hrs % 24;
  const days = (hrs - hrsDay) / 24;
  const pad = n => `00${n}`.slice(-2);

  if (s < 60) {
    return `${mins}m ${pad(secs)}s`;
  }
  if (s < 24 * 60) {
    return `${hrs}h ${pad(mins)}m`;
  }
  return `${days}d ${pad(hrsDay)}h ${pad(mins)}m`;
};

export const checkIfThisCurrentQuestion = (answers = [], question = {}) => {
  const savedAnswer =
    answers.find(answer => answer?.question?.key === question?.key) || [];

  const isCurrentQuestionSavedAnswer =
    savedAnswer?.question?.key === question?.key;

  return { isCurrentQuestionSavedAnswer, savedAnswer };
};

export const handleRecordVideoHTMLplayer = ({
  ref: { current = {} } = {},
  src = "",
  muted = true,
  volume = 0,
  autoplay = false,
  stream = null,
  videoRTCStream = null,
  sourceRef = null
}) => {
  try {
    if (current) {
      if (sourceRef && sourceRef.current) {
        sourceRef.current.src = src;
      } else {
        current.src = src;
      }

      current.muted = muted;
      current.volume = Number(volume);

      if (isChrome && (isMobileOnly || isTablet)) {
        current.autoplay = autoplay;
        current.preload = "auto";
      } else if (isMobileSafari) {
        current.autoplay = "autoplay";
      } else {
        current.autoplay = autoplay;
      }

      current.srcObject = null;
      current.srcObject = stream;

      // We need add aditional property videoRtc, to avoid global variable
      current.videoRTC = videoRTCStream;
    }

    if (
      current &&
      current.videoRTC &&
      typeof current.videoRTC.reset === "function" &&
      typeof current.videoRTC.startRecording === "function"
    ) {
      current.videoRTC.reset();
      current.videoRTC.startRecording();
    }
  } catch (error) {
    Sentry.captureException(error?.message || error);
  }
};

export const handleVideoExtenision = fileName => {
  const regex = /(?:\.([^.]+))?$/;
  const mediaExtensions = regex.exec(fileName);
  return mediaExtensions[1];
};

export const handleFileName = fileName => {
  const regex = /(?:\.([^.]+))?$/;
  const mediaExtensions = fileName ? regex.exec(fileName) : "webm";
  const name = fileName ? fileName.replace(regex, "") : "File";
  return {
    ext: mediaExtensions[1],
    name
  };
};

export const handleVideoBlobExtension = blobType => {
  const type = blobType?.split("/")?.pop();

  return type === "mp4" ? "mp4" : "webm";
};

export const isCandidateCanRetakeVideo = (attemptNumber, maxRetakes) =>
  maxRetakes !== 0 && (maxRetakes === null || maxRetakes > attemptNumber - 1);

const MimetypesKind = {
  opus: "video/ogg",
  ogv: "video/ogg",
  mp4: "video/mp4",
  mov: "video/mp4",
  m4v: "video/mp4",
  mkv: "video/x-matroska"
};

export const canPlayExtension = mediaExtension => {
  const video = document.createElement("video");
  return video.canPlayType(
    MimetypesKind[mediaExtension] || `video/${mediaExtension}`
  );
};

export const customTimeFormat = (seconds, guide) => {
  seconds = seconds < 0 ? 0 : seconds;
  let s = Math.floor(seconds % 60);
  let m = Math.floor((seconds / 60) % 60);
  let h = Math.floor(seconds / 3600);
  const gm = Math.floor((guide / 60) % 60);
  const gh = Math.floor(guide / 3600);

  if (isNaN(seconds) || seconds === Infinity) {
    h = m = s = "-";
    return `${h}:${s}`;
  }
  h = h > 0 || gh > 0 ? `${h}:` : "";
  m = `${(h || gm >= 10) && m < 10 ? `0${m}` : m}:`;
  h = parseInt(h) < 10 ? `0${h}` : h;
  s = parseInt(s) < 10 ? `0${s}` : s;
  return h + m + s;
};

export const formatStringtoDuration = (duration, isEmptyDurationAllowed = false) => {
  if ((!duration && !isEmptyDurationAllowed) || (isNaN(duration) || duration === Infinity)) return "";

  const seconds = Math.floor(duration % 60);
  const minutes = Math.floor((duration - seconds) / 60);
  return `${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
};

export const isRetakePromptsEnabled =
  (process.env.REACT_APP_RETAKE_PROMPTS_ENABLED === "true" ||
    process.env.REACT_APP_RETAKE_PROMPTS_ENABLED === true) &&
  // retake prompts doesnt work on android devices. works on ios for audio only
  (isMobile ? isIOS : true);

export const isRetakeSelectionOverlayEnabled =
  (process.env.REACT_APP_RETAKE_SELECTION_OVERLAY_ENABLED === "true" ||
    process.env.REACT_APP_RETAKE_SELECTION_OVERLAY_ENABLED === true);

export const isFlagRetakePromptsEnabled =
  process.env.REACT_APP_RETAKE_PROMPTS_ENABLED === "true" ||
  process.env.REACT_APP_RETAKE_PROMPTS_ENABLED === true;

export const isAutoSaveBlobEnabled =
  (process.env.REACT_APP_AUTOSAVE_BLOB_ENABLED === "true" ||
    process.env.REACT_APP_AUTOSAVE_BLOB_ENABLED === true) &&
  (isMobile ? isIOS : true);

export const shouldLoadM3u8 =
  process.env.REACT_APP_LOAD_M3U8 === "true" ||
  process.env.REACT_APP_LOAD_M3U8 === true;

export const RetakePromptsWrapper = ({ children, fallbackComponent }) => {
  if (isRetakePromptsEnabled) {
    return children;
  }

  return fallbackComponent;
};

export const NOT_ALLOWED_ERROR = "NotAllowedError";
export const PERMISSION_DENIED_ERROR = "PermissionDeniedError";
export const NOT_FOUND_ERROR = "NotFoundError";
export const NOT_READABLE_ERROR = "NotReadableError";

export const validateTimestamp = startDate => {
  // Handle number timestamps
  if (typeof startDate === "number") {
    const timestamp = moment(startDate).valueOf();
    return !isNaN(timestamp) ? timestamp : null;
  }

  // Handle date strings
  if (typeof startDate === "string") {
    const timestamp = moment(new Date(startDate)).valueOf();
    return !isNaN(timestamp) ? timestamp : null;
  }

  return null;
};

export const filterProperties = (data, conditions) => Object.entries(data)
  .filter(([key]) => !conditions[key])
  .reduce((acc, [key, value]) => ({
    ...acc,
    [key]: value
  }), {});
