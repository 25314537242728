/* eslint-disable no-unused-expressions */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useMemo } from "react";
import { useTranslation, withTranslation } from "react-i18next";
import PropTypes from "prop-types";

import { Reload } from "mixins/svgIcons";
import AnswerHeader from "components/Common/AnswerHeader";

import "../index.scss";
import "../../IDVCheck/CandidateIdvCheck/styles.scss";
import SkeletonWrapper from "containers/Hoc/SkeletonWrapper";
import { getIsJobCandidateLoading } from "store/modules/jobs/selectors";
import { isPublicInfoLoading } from "store/modules/public/selectors";
import { connect } from "react-redux";
import { getBorderColorContrastOrHex, getColorContrastOrHex } from "mixins/helperDynamicStyles";
import MultipleChoiceCheckboxItem from "../MultipleChoiceCheckboxItem";

const MultipleChoicePreview = ({
  history,
  jobId,
  userId,
  question,
  questionNumber,
  withButton,
  textSize,
  retakeAnswerAfterFileUpload,
  answer,
  questionTextProps,
  memorizedCompanyInfo,
  thinkingTime,
  spentTime,
  currentQuestion,
  isRecruiter,
  isJobCandidateLoading,
  isShowcaseLoading
}) => {
  const { t } = useTranslation();
  const selectedAnswersIds =
    answer?.type_specific_data?.selected_answer_options?.map(a => a.option);
  const returnCreateQuestion = useCallback(() => {
    retakeAnswerAfterFileUpload({ userId, questionKey: question.key });

    history.push({
      pathname: `/candidate/video-questions/create/${userId}/${jobId}/${question.key}`
    });
  }, []);

  const hasCorrectOption =
    question?.type_specific_data?.answer_options?.filter(a => a.is_correct)
      ?.length > 0;

  const questions = useMemo(() => {
    if (isRecruiter) {
      const correctSelectedAnswers = [];
      const correctUnselectedAnswers = [];
      const incorrectSelectedAnswers = [];
      const incorrectUnselectedAnswers = [];

      question?.type_specific_data?.answer_options?.forEach(a => {
        if (a.is_correct) {
          if (selectedAnswersIds?.includes(a.key)) {
            correctSelectedAnswers.push(a);
          } else {
            correctUnselectedAnswers.push(a);
          }
        } else if (selectedAnswersIds?.includes(a.key)) {
          incorrectSelectedAnswers.push(a);
        } else {
          incorrectUnselectedAnswers.push(a);
        }
      });

      return {
        sortedQuestions: [
          ...correctSelectedAnswers,
          ...incorrectSelectedAnswers,
          ...correctUnselectedAnswers,
          ...incorrectUnselectedAnswers
        ],
        correctSelectedAnswers,
        incorrectSelectedAnswers,
        correctUnselectedAnswers
      };
    }
    return {
      sortedQuestions: question?.type_specific_data?.answer_options
    };
  }, [question?.type_specific_data?.answer_options, isRecruiter]);

  const extraTextStatus = useMemo(() => {
    if (hasCorrectOption === false) {
      return "";
    }
    const textStrings = [];
    if (questions?.correctSelectedAnswers?.length) {
      textStrings.push(
        `${t("multipleChoice.correctCount", { value: questions?.correctSelectedAnswers?.length })}`
      );
    }
    if (questions?.incorrectSelectedAnswers?.length) {
      textStrings.push(
        `${t("multipleChoice.incorrectCount", { value: questions?.incorrectSelectedAnswers?.length })}`
      );
    }
    if (questions?.correctUnselectedAnswers?.length) {
      textStrings.push(
        `${t("multipleChoice.missedCount", { value: questions?.correctUnselectedAnswers?.length })}`
      );
    }

    if (textStrings.length === 0) {
      return "";
    }

    return ` - ${textStrings.join(", ")}`;
  }, [questions]);

  return (
    <div className="video__preview-item">
      <AnswerHeader
        questionNumber={questionNumber}
        thinkingTime={thinkingTime}
        spentTime={spentTime}
        textSize={textSize}
        questionTextProps={questionTextProps}
        questionText={question?.text}
        currentQuestion={currentQuestion}
      />

      <SkeletonWrapper
        skeletonProps={{
          count: 1,
          enableAnimation: true,
          height: "30vh",
          borderRadius: 20
        }}
        skeletonWrapperProps={{
          style: {
            display: "block",
            lineHeight: 1.5,
            marginBottom: "0.5rem",
            width: "100%"
          }
        }}
        isLoading={isJobCandidateLoading || isShowcaseLoading}
      >
        <>
          <div className="mcq-answer-container">
            {questions?.sortedQuestions?.map(option => (
              <MultipleChoiceCheckboxItem
                isRadio={question?.answer_type === "multiple_choice"}
                title={option.text}
                key={option.key}
                id={option.key}
                isChecked={selectedAnswersIds?.includes(option.key)}
                viewOnly
                isCorrect={option.is_correct}
                isRecruiter={isRecruiter}
                hasCorrectOption={hasCorrectOption}
              />
            ))}
          </div>
          {isRecruiter && (
            <div className="video__preview-status">
              <p className="video__preview-status-title n-font-extra-small n-font-regular">
                {selectedAnswersIds?.length}
                {" "}
                {t("multipleChoice.of")}
                {" "}
                {questions?.sortedQuestions?.length}
                {" "}
                {t("multipleChoice.selected")}
                {extraTextStatus}
              </p>
            </div>
          )}

          {withButton && (
            <div className="video__preview-button">
              <button
                type="button"
                className="
                  n-button__large-border
                  n-border-purple-100
                  n-purple-100
                  flex flex-row items-center
                "
                onClick={returnCreateQuestion}
                style={
                  memorizedCompanyInfo && {
                    borderColor: getBorderColorContrastOrHex({
                      hex: memorizedCompanyInfo.button_color
                    }),
                    color: getColorContrastOrHex({
                      hex: memorizedCompanyInfo.button_color
                    })
                  }
                }
              >
                <Reload
                  svgIconClass="n-icon__large right"
                  fill={getColorContrastOrHex({ hex: memorizedCompanyInfo.button_color })}
                />
                {t("button.edit")}
              </button>
            </div>
          )}
        </>
      </SkeletonWrapper>
    </div>
  );
};

MultipleChoicePreview.defaultProps = {
  history: null,
  jobId: "",
  userId: "",
  question: {},
  questionNumber: "",
  withButton: true,
  textSize: "",
  retakeAnswerAfterFileUpload: null,
  answer: {},
  questionTextProps: null,
  memorizedCompanyInfo: {},
  thinkingTime: 0,
  spentTime: 0
};

MultipleChoicePreview.propTypes = {
  history: PropTypes.shape({
    length: PropTypes.number,
    push: PropTypes.func
  }),
  jobId: PropTypes.string,
  userId: PropTypes.string,
  question: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.number,
    text: PropTypes.string,
    key: PropTypes.string,
    answer_type: PropTypes.string,
    type_specific_data: PropTypes.shape({
      answer_options: PropTypes.arrayOf(
        PropTypes.shape({
          key: PropTypes.string,
          text: PropTypes.string
        })
      )
    })
  }),
  questionNumber: PropTypes.number,
  withButton: PropTypes.bool,
  textSize: PropTypes.string,
  retakeAnswerAfterFileUpload: PropTypes.func,
  answer: PropTypes.shape({
    html_text: PropTypes.string,
    text: PropTypes.string,
    type_specific_data: PropTypes.shape({
      selected_answer_options: PropTypes.arrayOf(
        PropTypes.shape({
          option: PropTypes.string
        })
      )
    })
  }),
  questionTextProps: PropTypes.shape({}),
  memorizedCompanyInfo: PropTypes.shape({
    button_color: PropTypes.string
  }),
  thinkingTime: PropTypes.number,
  spentTime: PropTypes.number,
  isRecruiter: PropTypes.bool.isRequired,
  currentQuestion: PropTypes.shape({
    question: PropTypes.shape({
      html_text: PropTypes.string
    })
  }).isRequired
};

const mapStateToProps = state => ({
  isJobCandidateLoading: getIsJobCandidateLoading(state),
  isShowcaseLoading: isPublicInfoLoading(state)
});

export default connect(mapStateToProps)(withTranslation()(MultipleChoicePreview));
