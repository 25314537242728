import React, { useMemo } from "react";
import { ANSWER_STATUS, FILE_TYPE } from "configs/jobs/constants";
import { withTranslation } from "react-i18next";
import CustomTooltip from "components/Common/CustomTooltip/CustomTooltip.tsx";
import { getCandidateAnswers } from "store/modules/jobs/selectors";
import { connect } from "react-redux";
import DownloadAllButton from "./DownloadAllButton";

const DownloadAllButtonWrapper = ({
  t,
  realtimeAnswers,
  candidateAnswers,
  handleDownload
}) => {
  const sortedAnswers = useMemo(() => candidateAnswers.sort(
    (a, b) => a.question?.order - b.question?.order
  ), [candidateAnswers]);

  const shouldShowDownloadAll = useMemo(() => {
    if (!realtimeAnswers || !sortedAnswers) return false;

    return !Object.keys(realtimeAnswers).some(key => {
      const apiAnswer = sortedAnswers.find(a => a.key === key);
      const realtimeStatus = realtimeAnswers[key]?.status;
      const apiStatus = apiAnswer?.status;

      // Enable download if answer is processed or failed
      if ([ANSWER_STATUS.processed, ANSWER_STATUS.failed].includes(realtimeStatus) ||
          [ANSWER_STATUS.processed, ANSWER_STATUS.failed].includes(apiStatus)) {
        return false;
      }

      // Enable download if video/audio is reloaded
      if (apiAnswer?.is_reloaded) {
        return false;
      }

      // Enable download if IDV is skipped and received
      if (apiAnswer?.is_skipped && (
        apiStatus === ANSWER_STATUS.received ||
        realtimeStatus === ANSWER_STATUS.received
      )) {
        return false;
      }

      // Enable download for file type answers that are received or unsafe
      if (apiAnswer?.question?.answer_type === FILE_TYPE &&
          [ANSWER_STATUS.received, ANSWER_STATUS.unsafe].includes(realtimeStatus)) {
        return false;
      }

      return true;
    });
  }, [realtimeAnswers, sortedAnswers]);

  return (
    <>
      {
        shouldShowDownloadAll ? (

          <DownloadAllButton
            shouldShowDownloadAll={shouldShowDownloadAll}
            handleDownload={handleDownload}
          />
        ) : (
          <CustomTooltip content={t("job.candidate.download.disabledTooltip")}>
            <DownloadAllButton
              shouldShowDownloadAll={shouldShowDownloadAll}
              handleDownload={handleDownload}
            />
          </CustomTooltip>
        )
      }
    </>
  );
};

const mapStateToProps = state => ({
  candidateAnswers: getCandidateAnswers(state)
});

export default connect(mapStateToProps)(withTranslation()(DownloadAllButtonWrapper));
