import React from "react";
import PropTypes from "prop-types";

const CandidateTextIcon = ({ fill = "#5A2AF1" }) => (
  <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg" className="mx-auto">
    <path d="M46.7143 45.4243C47.3571 45.4243 48 46.0682 48 46.7121C48 47.4366 47.3571 48 46.7143 48H40.2857C39.5625 48 39 47.4366 39 46.7121C39 46.0682 39.5625 45.4243 40.2857 45.4243H42.1339L38.9196 37.697H21L17.7857 45.4243H19.7143C20.3571 45.4243 21 46.0682 21 46.7121C21 47.4366 20.3571 48 19.7143 48H13.2857C12.5625 48 12 47.4366 12 46.7121C12 46.0682 12.5625 45.4243 13.2857 45.4243H14.9732L28.7946 12.6641C29.1964 11.7786 30.7232 11.7786 31.125 12.6641L44.9464 45.4243H46.7143ZM22.125 35.1213H37.7946L30 16.5277L22.125 35.1213Z" fill={fill} />
  </svg>
);

CandidateTextIcon.defaultProps = {
  fill: "#5A2AF1"
};

CandidateTextIcon.propTypes = {
  fill: PropTypes.string
};

export default CandidateTextIcon;
