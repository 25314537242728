/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";

import { ROLE_ADMIN } from "configs/user/rolesConfig";
import Header from "containers/Common/HeaderContainer";
import UsersTable from "containers/Tables/UsersContainer";
import ProgressPanel from "components/Common/Progress/Panel";
import AddNewUser from "components/AddNewUser";
import PageWrapper from "hoc/PageWrapper";
import { Tick } from "mixins/svgIcons";

import "./styles.scss";

const Users = ({
  t,
  isAbilityAddUser,
  isSubscriptionActive,
  isCardInfoLoading,
  isUsersAdded,
  addNewUsers,
  setModal,
  setIsUsersAdded,
  clearBillingError,
  clearUserError,
  dataUsers = [{}],
  currentUser,
  currentUser: { role: userRole },
  history,
  error,
  isLockedCardWithActiveSubscription,
  isTrialCompleted,
  isUsersLoading
}) => {
  const [userToAdd, setUserToAdd] = useState(null);
  const prevDataUsersLength = useRef(dataUsers.length);
  const addNewUserRef = useRef(null);

  useEffect(() => {
    if (currentUser.role !== ROLE_ADMIN) {
      history.push("/jobs");
    }

    return () => clearUserError();
  }, [history, currentUser.role, clearUserError]);

  useEffect(() => {
    if (userToAdd && isSubscriptionActive) {
      addNewUsers(userToAdd);
    }
  }, [isSubscriptionActive]);

  const handleNewUsers = invites => {
    if (isAbilityAddUser) {
      return addNewUsers(invites);
    }

    if (isLockedCardWithActiveSubscription) {
      return setModal({
        type: "isLockedCard",
        cancelPath: "/users"
      });
    }

    setUserToAdd(invites);

    return history.push(
      "/plans?utm_source=internal&utm_medium=user&utm_campaign=locked_users"
    );
  };

  useEffect(() => {
    if (
      dataUsers.length > Number(prevDataUsersLength.current) &&
      addNewUserRef?.current?.clearField
    ) {
      addNewUserRef.current.clearField();
    }

    return () => {
      prevDataUsersLength.current = dataUsers.length;
    };
  }, [dataUsers, addNewUserRef]);

  return (
    <PageWrapper header={<Header />}>
      <Container className="users">
        {isTrialCompleted ? (
          <Link
            className="n-font-medium n-purple-100 users__back-button"
            to="/plans?utm_source=internal&utm_medium=user&utm_campaign=header_upgrade"
          >
            {t("link.backToPlans")}
          </Link>
        ) : null}
        <Row justify="center">
          <Col md={4}>
            <ProgressPanel>
              <ProgressPanel.Title>
                <div className="flex flex-row items-center text-base">
                  {currentUser.name}
                  <span
                    className="flex flex-row items-center justify-center"
                    style={{
                      lineHeight: "14px"
                    }}
                  >
                    {currentUser.plan_name}
                    <Tick height="8px" width="12px" />
                  </span>
                </div>
              </ProgressPanel.Title>
            </ProgressPanel>
          </Col>

          {userRole === ROLE_ADMIN && (
            <Col lg={{ span: 4, offset: 4 }} md={{ span: 6, offset: 2 }}>
              <AddNewUser
                ref={addNewUserRef}
                isUsersAdded={isUsersAdded}
                isCardInfoLoading={isCardInfoLoading}
                addNewUsers={handleNewUsers}
                setIsUsersAdded={setIsUsersAdded}
                clearUserError={clearUserError}
                clearBillingError={clearBillingError}
                currentUser={currentUser}
                error={error}
                isUsersLoading={isUsersLoading}
              />
            </Col>
          )}
        </Row>
        <Row justify="center">
          <Col md={12}>
            <UsersTable />
          </Col>
        </Row>
      </Container>
    </PageWrapper>
  );
};

Users.defaultProps = {
  isAbilityAddUser: false,
  isLockedCardWithActiveSubscription: false,
  isSubscriptionActive: false,
  isCardInfoLoading: false,
  isUsersAdded: false,
  setIsUsersAdded: undefined,
  setModal: undefined,
  clearBillingError: undefined,
  clearUserError: undefined,
  profileInfo: undefined,
  currentUser: undefined,
  dataUsers: undefined,
  history: undefined,
  error: undefined
};

Users.propTypes = {
  t: PropTypes.func.isRequired,
  isAbilityAddUser: PropTypes.bool,
  isLockedCardWithActiveSubscription: PropTypes.bool,
  isSubscriptionActive: PropTypes.bool,
  isCardInfoLoading: PropTypes.bool,
  isUsersAdded: PropTypes.bool,
  addNewUsers: PropTypes.func.isRequired,
  setIsUsersAdded: PropTypes.func,
  setModal: PropTypes.func,
  clearBillingError: PropTypes.func,
  clearUserError: PropTypes.func,
  profileInfo: PropTypes.shape({
    key: PropTypes.string,
    name: PropTypes.string
  }),
  currentUser: PropTypes.shape({
    subscription: PropTypes.object,
    role: PropTypes.string,
    name: PropTypes.string,
    plan_name: PropTypes.string
  }),
  dataUsers: PropTypes.arrayOf(PropTypes.shape({})),
  history: PropTypes.shape({
    length: PropTypes.number,
    push: PropTypes.func
  }),
  error: PropTypes.string,
  isTrialCompleted: PropTypes.bool.isRequired,
  isUsersLoading: PropTypes.bool.isRequired
};

export default withTranslation()(Users);
